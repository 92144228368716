.email-section{
    padding: 0 2rem;
    display: flex;
    gap: 10rem;
}
.left-e hr{
    margin: -10px 0;
    width: 10.5rem;
    border: 2px solid var(--orange);
    border-radius: 20%;
    position: absolute;
}
.left-e{
    position: relative;
    color: white;
    font-weight: 700;
    font-size: 3rem;
}
.left-e div{
    display: flex;
    gap: 1rem;
}
.right-e{
    display: flex;
    align-items: flex-end;
}
.email-container form{
    display: flex;
    gap: 3rem;
    padding: 1rem 2rem;
    background-color: gray;
}
.email-container input{
    outline: none;
    padding: 1px 2px;
    background-color: initial;
    border: 0;
}
input::placeholder{
    color: var(--lightgray);
}
.email-container button{
    background-color: var(--orange);
    color: white;
}
@media screen and (max-width:768px) {
    .email-section{
        flex-direction: column;
        gap: 2rem;
    }
    .left-e{
        font-size: x-large;
    }
}