.program-card{
    background-color: gray;
    display: flex;
    flex-direction: column;
    color: white;
    padding: 2rem;
    gap: 1rem;
    justify-content: space-between;
}
.program-card:hover{
    background: var( --planCard);
    cursor: pointer;
}
.program-card>:nth-child(1){
    width: 2rem;
    height: 2rem;
}
.program-card>:nth-child(1) path{
    fill: white;
}
.program-card>:nth-child(2){
    font-size: 1rem;
    font-weight: bold;
}
.program-card>:nth-child(3){
    font-size: 0.9rem;
    line-height: 25px;
}
.card-bottom{
    display: flex;
    justify-content: space-between;
    width: 50%;
    align-items: center;
}
.card-bottom button{
    background-color: transparent;
    border: 0;
    color: white;
    font-size: 1rem;
}
.card-bottom img{
    width: 1rem;
    height: 1rem;
}