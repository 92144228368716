.plans-container {
    display: flex;
    gap: 4rem;
    padding: 0 2rem;
    flex-direction: column;
    position: relative;
}

.plans-header {
    display: flex;
    justify-content: center;
    font-size: 3rem;
    gap: 2rem;
    font-weight: bold;
    font-style: italic;
    color: white;
}
.plans-cards{
    display: flex;
    gap: 3rem;
    justify-content: center;
}
.plans-cards>:nth-child(2){

    background: var(--planCard);
    transform: scale(1.1);
}
.plans-cards>:nth-child(2)>:nth-child(1){
    fill: white;
}
.card{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
    color: white;
    padding: 1.5rem;
    background-color: var(--caloryCard);
}
.card>svg{
    height: 2rem;
    width: 2rem;
    fill: var(--orange);
}
.plans-cards>:nth-child(3) svg path{
    fill: var(--orange);
}
.card>span:nth-child(3){
    font-size: 3rem;
    font-weight: bold;
}
.featuers{
    flex-direction: column;
    width: 95%;
}
.featuers ,.featuers div{
    display: flex;
    gap: 1rem;
}
.featuers div img{
    width: 1rem;
    height: 1rem;
}
.featuers div{
    align-items: center;
}
.benefits span{
    font-size: 0.8rem;
    margin-right: 5px;
}
.benefits img{
    width: 0.5rem;
    height: 0.5rem;
}
.card>:last-child{
    align-self: center;
}
.plans-cards>:nth-child(2) .benefits~button{
    color: var(--orange);
}
.blur-p1{
    background-color: rgba(253,120,43,.69);
    left: 0rem;
    top: 6rem;
    height: 23rem;
    width: 32rem;
}
.blur-p2{
    background-color: rgba(253,120,43,.69);
    right: 0rem;
    top: 6rem;
    height: 23rem;
    width: 32rem;
}
@media screen and (max-width:768px) {
    .plans-header{
        flex-direction: column;
        align-items: center;
        font-size: x-large;
    }
    .plans-cards{
        flex-direction: column;
        align-items: center;
    }
    .card{
        width: fit-content;
    }
    .plans-cards>:nth-child(2){
        transform: none;
    }
}