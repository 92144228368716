.programs{
    display: flex;
    flex-direction: column;
    gap: 3rem;
    font-weight: bold;
    padding: 0 2rem;
}
.programs-text{
    display: flex;
    gap: 5rem;
    justify-content: center;
    font-size: 3rem;
    font-style: italic;
}
.programs-text span:nth-child(2){
    font-weight: bold;
    color: white;
}
.programs-divs{
    position: relative;
    display: flex;
    gap: 1rem;
}
@media screen and (max-width:768px){
    .programs{
        margin-top: 2rem;
    }
    .programs-text{
        gap: 1rem;
        flex-direction: column;
        font-size: x-large;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    .programs-divs{
        width: 100%;
        flex-direction: column;
    }
}