.hero{
    display: flex;
    justify-content: space-between;
}
.left-h{
    position: relative;
    padding: 2rem;
    padding-top: 1.5rem;
    flex: 3;
    display: flex;
    gap: 2rem;
    flex-direction: column;
}
.blur-h{
    background-color: rgba(253,120,43,.69);
    position: absolute;
    height: 30rem;
    top: 2rem;
    left: 0;
    width: 22rem;
}
.the-best-ad{
    margin-top: 4rem;
    background-color: #363d42;
    border-radius: 4rem;
    width: fit-content;
    padding: 20px 13px;
    color: white;
    text-transform: uppercase;
    position: relative;
    display: flex ;
    justify-content: flex-start;
    align-items: center;
}
.the-best-ad>div{
    position: absolute;
    background-color:var(--orange);
    width: 5.4rem;
    height: 80%;
    left: 8px;
    border-radius: 4rem;
    z-index: 0;
}
.the-best-ad>span{
    z-index: 1;
}
.hero-text{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 4.5rem;
    overflow: inherit;
    color: white;
}
.hero-text>div:nth-of-type(3){
    letter-spacing: 1px;
    text-transform: none;
    font-size: 1rem;
    font-weight: normal;
    width: 85%;
}
.figures{
    display: flex;
    gap: 2rem;
}
.figures div{
    display: flex;
    flex-direction: column;
}
.figures>div span:nth-of-type(1){
    font-size: 2rem;
    color: white;
}
.figures>div span:nth-of-type(2){
    color: var(--gray);
    text-transform: uppercase;
}
.hero-buttons{
    display: flex;
    gap: 1.6rem;
}
.hero-buttons button{
    background-color: transparent;
    padding: 8px 15px;
    font-size: 0.9rem;
    font-weight: 300;
    color: white;
    text-transform: capitalize;
    transition: 30ms all;
}
.hero-buttons button:hover{
    cursor: pointer;
    transform: scale(1.1,1.1);
}
.hero-buttons button:nth-of-type(1){
    background-color: var(--orange);
    border: var(--orange);
}
.hero-buttons button:nth-of-type(2){
    border: 2px solid var(--orange);
}

.right-h{
    position: relative;
    flex: 1;
    background-color: var(--orange);
    display: flex;
    flex-direction: column;
}
.right-h .buttons{
    font-weight: bold;
    position: absolute;
    right: 3rem;
    top: 2rem;
    background-color: #fff;
}
.heart-rate{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: var(--darkGrey);
    width: fit-content;
    padding: 1rem;
    border-radius: 5px;
    position: absolute;
    top: 7rem;
    right: 4rem;
}
.heart-rate img{
    width: 2rem;
}
.heart-rate span:nth-of-type(1){
    color: var(--gray);
}
.heart-rate span:nth-of-type(2){
    color: white;
    font-size: 1.5rem;
}
.hero-image{
    position: absolute;
    top: 10rem;
    right: 8rem;
    width: 23rem;
    z-index: 3;
}
.hero-image-back{
    position: absolute;
    top: 4rem;
    right: 20rem;
    width: 15rem;
    z-index: -1;
}
.calories{
    border-radius: 5px;
    background-color: var(--caloryCard);
    display: flex;
    gap: 2rem;
    padding: 1rem;
    width: fit-content;
    position: absolute;
    top: 32rem;
    right: 28rem;
}
.calories-text{
    display: flex;
    flex-direction: column;
}
.calories img{
    width: 3rem;
}
.calories-text span:nth-of-type(1){
    color: var(--gray);
}
.calories-text span:nth-of-type(2){
    color: white;
    font-size: 1.5rem;
}
@media screen and (max-width:768px){
    .hero{
        flex-direction: column;
    }
    .blur-h{
        width: 14rem;
    }
    .the-best-ad{
        font-size: 10px;
        margin-top: 0;
        align-self: center;
    }
    .hero-text{
        align-items: center;
        justify-content: center;
        font-size: xx-large;
    }
    .hero-text>:last-child{
        font-size: small;
        font-weight: 200;
        text-align: center;
        letter-spacing: 1px;
    }
    .figures{
        gap: 1rem;
    }
    .figures>div span:nth-of-type(1){
        font-size: medium;
    }
    .figures>div span:nth-of-type(2){
        font-size: small;
    }
    .hero-buttons{
        align-self: center;
    }
    .right-h{
        background: none;
        position: relative;
    }
    .hero-image{
        width: 12rem;
    }
    .heart-rate {
        left: 1rem;
        top: 2rem;
    }
    .calories{
        position: relative;
        left: 2rem;
        top: 5rem;
    }
    .calories>img{
        width: 2rem;
    }
    .calories> div >:nth-child(2){
        font-size: 1rem;
    }
    .hero-image{
        position: relative;
        width: 15rem;
        left: 3rem;
        top: 4rem;
        align-self: center;
    }
    .hero-image-back{
        width: 15rem;
        left: 2rem;
        top: 0;
    }
}