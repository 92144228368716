.footer-container{
    position: relative;
}
.footer,.social-icon{
    display: flex;
    gap: 4rem;
}
.footer{
    position: relative;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 20rem;
    padding: 1rem 2rem;
}
.social-icon img{
    width: 2rem;
    height: 2rem;
}
.footer-logo img{
    width: 10rem;
}
.blur1{
    background-color: var(--orange);
    left: 15%;
    bottom: 0;
}
.blur2{
    background-color: red;
    right: 15%;
    bottom: 0;
}