.header{
    display: flex;
    justify-content: space-between;
}
.logo{
    width: 10rem;
    height: 3rem;
}
.header-menu ul{
    list-style: none;
    display: flex;
    gap: 2rem;
    color: white;
}
.header-menu li:hover{
    color: var(--orange);
    cursor: pointer;
}
@media screen and (max-width:768px) {
    .header-menu{
        position: fixed;
        background-color: var(--appColor);
        z-index: 5;
        right: 2rem;
        top: 2rem;
        padding: 2rem;
    }
    ul{
        padding: 0;
        margin: 0;
        flex-direction: column;
    }
    .bars{
        display: inline-block;
        padding: 7px;
        position: fixed;
        z-index: 4;
        border-radius: 5px;
        background-color: var(--appColor);
        right: 2rem;
    }
    .bars img{
        width: 1.5rem;
        height: 1.5rem;
    }
}