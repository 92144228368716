.testimonials{
    display: flex;
    padding: 0 2rem;
    gap: 2rem;
}
.left-t{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    flex: 1.1;
    color: white;
}
.left-t>:first-child{
    font-weight: bold;
    color: var(--orange);
}
.text-t{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.text-t>:nth-child(1),.text-t>:nth-child(2){
    font-weight: bold;
    font-size: 3rem;
}
.text-t>:nth-child(3){
    line-height: 40px;
    text-align: justify;
    letter-spacing: 2px;
}
.text-t>:nth-child(4)>:first-child{
    color: var(--orange);
}
.right-t{
    flex: 1;
    display: flex;
    justify-content: flex-end;
    position: relative;
}
.arrows{
    position: absolute;
    left: 0.5rem;
    bottom: 1rem;
    display: flex;
    gap: 1rem;
}
.right-t-image{
    width: 17rem;
    height: 20rem;
    position: absolute;
    right: 8rem;
    top: 2rem;
}
.arrows img{
    object-fit: cover;
    width: 1.5rem;
    height: 1.5rem;
}
.arrows img:hover{
    cursor: pointer;
}
.right-t-image img{
    width: 17rem;
    height: 20rem;
    z-index: 2;
    position: relative;
}
.right-t-image div:nth-child(1){
    height: 100%;
    width: 100%;
    background: var(--planCard);
    position: absolute;
    z-index: 1;
    right: -1rem;
    top: 1rem;
}
.right-t-image div:nth-child(2){
    height: 100%;
    width: 100%;
    background-color: transparent;
    position: absolute;
    z-index: 1;
    border: 2px solid var(--orange);
    left: -1rem;
    bottom: 1rem;
}
@media screen and (max-width:768px) {
    .testimonials{
        flex-direction: column;
    }
    .text-t>:nth-child(1),.text-t>:nth-child(2){
        font-size: xx-large;
    }
    .right-t-image>:nth-child(1),.right-t-image>:nth-child(2){
        display: none;
    }
    .right-t{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2rem;
    }
    .right-t-image{
        right: 0;
        top: 0;
    }
    .right-t-image , .arrows{
        position: relative;
    }
    .right-t-image img{
        align-self: center;
    }
    .arrows{
        gap: 8px;
        display: flex;
        left: 0;
        bottom: 0;
    }
}